import React from 'react';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import Container from 'components/Container';
import Tooltip from 'components/Tooltip';
import QuestionCircle from 'svg/question-circle.svg';
import './CostVersus.module.css';

const CostVersus = ({ intl }) => (
  <>
    <Container>
      <div styleName="cost-breakdown">
        <h3>
          {intl.formatMessage({ id: 'synthall.cost-breakdown' })}
        </h3>

        <div styleName="cost-versus-header">
          <h5>
            {intl.formatMessage({ id: 'synthall.without-synthall' })}
          </h5>

          <span>
            {intl.formatMessage({ id: 'synthall.vs' })}
          </span>

          <h5>
            {intl.formatMessage({ id: 'synthall.with-synthall' })}
          </h5>
        </div>
      </div>
    </Container>

    <div styleName="cost-versus-container">
      <Container>
        <div styleName="cost-versus">
          <div>
            <span styleName="cost">
              {intl.formatMessage({ id: 'synthall.without-cost' })}
            </span>

            <p>
              {intl.formatMessage({ id: 'synthall.without-info' })}
              <Tooltip
                message={intl.formatMessage({ id: 'synthall.without-tooltip' })}
                link="https://aws.amazon.com/sagemaker/groundtruth/pricing/"
              >
                <QuestionCircle />
              </Tooltip>
            </p>
          </div>

          <div>
            <span styleName="cost-gradient">
              {intl.formatMessage({ id: 'synthall.with-cost' })}
            </span>

            <p>
              {intl.formatMessage({ id: 'synthall.with-info' })}
            </p>
          </div>
        </div>
      </Container>
    </div>
  </>
);

CostVersus.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(CostVersus);
