import React from 'react';
import Helmet from 'react-helmet';

import Layout from 'components/Layout';
import SynthallPage from 'components/pages/SynthallPage';

const Synthall = () => (
  <Layout>
    <Helmet title="Synthall" />

    <SynthallPage />
  </Layout>
);

export default Synthall;
