import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import Container from 'components/Container';
import DiagramWithSynthallZh from 'images/with-synthall-zh-2x.png';
import DiagramWithoutSynthallZh from 'images/without-synthall-zh-2x.png';
import DiagramWithSynthallEn from 'images/with-synthall-en-2x.png';
import DiagramWithoutSynthallEn from 'images/without-synthall-en-2x.png';
import './Diagrams.module.css';

const DIAGRAMS = {
  en: {
    without: DiagramWithoutSynthallEn,
    with: DiagramWithSynthallEn,
  },
  zh: {
    without: DiagramWithoutSynthallZh,
    with: DiagramWithSynthallZh,
  },
};

const Diagram = ({ locale, type }) => {
  const image = DIAGRAMS[locale][type];

  if (!image) {
    return null;
  }

  return <img src={image} alt="" />;
};

Diagram.propTypes = {
  locale: PropTypes.oneOf(
    Object.keys(DIAGRAMS),
  ).isRequired,
  type: PropTypes.oneOf(['with', 'without']).isRequired,
};

const Diagrams = ({ intl }) => (
  <Container>
    <section styleName="with-without">
      <div styleName="diagram">
        <h4>
          {intl.formatMessage({ id: 'synthall.without-synthall' })}
        </h4>
        <Diagram locale={intl.locale} type="without" />
      </div>

      <div styleName="diagram">
        <h4>
          {intl.formatMessage({ id: 'synthall.with-synthall' })}
        </h4>
        <Diagram locale={intl.locale} type="with" />
      </div>
    </section>
  </Container>
);

Diagrams.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Diagrams);
