import React from 'react';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import Waves from 'svg/waves.svg';
import Checkmark from 'svg/checkmark.svg';
import Sliders from 'svg/sliders.svg';
import Intersection from 'svg/intersection.svg';
import InfiniteData from 'svg/infinite-data.svg';
import HumanError from 'svg/human-error.svg';
import Labels from 'svg/labels.svg';
import Sort from 'svg/sort.svg';

import Container from 'components/Container';
import Hero from 'components/Hero';
import Features from 'components/Features';
import Diagrams from './Diagrams';
import CostVersus from './CostVersus';
import './SynthallPage.module.css';

// TODO: move these to a content module
const whySynthall = [{
  title: 'synthall.iterate',
  content: 'synthall.tailor-dataset',
}, {
  title: 'synthall.toolchain',
  content: 'synthall.scenes',
}, {
  title: 'synthall.pipeline',
  content: 'synthall.tools',
}];

const features = [{
  Icon: Intersection,
  label: 'synthall.features.scenes',
}, {
  Icon: Labels,
  label: 'synthall.features.labeled',
}, {
  Icon: HumanError,
  label: 'synthall.features.human-error',
}, {
  Icon: InfiniteData,
  label: 'synthall.features.infinite',
}, {
  Icon: Sliders,
  label: 'synthall.features.tweak',
}, {
  Icon: Sort,
  label: 'synthall.features.library',
}];

const SynthallPage = ({ intl }) => (
  <div>
    <div styleName="header-container">
      <Container>
        <header styleName="header">
          <div styleName="header-content">
            <div styleName="header-title">
              <h4>
                {intl.formatMessage({ id: 'synthall.program-data' })}
              </h4>

              <h1>
                Synthall
              </h1>
            </div>

            <p>
              {intl.formatMessage({ id: 'synthall.problem-solving' })}
            </p>
          </div>
          <div className="dots" />
        </header>
      </Container>
      <div styleName="gradient-background" />
      <Waves className="waves" />
    </div>

    <div styleName="how-synthall-works">
      <Container>
        <Hero
          title={intl.formatMessage({ id: 'synthall.how' })}
          text={(
            <>
              {intl.formatMessage({ id: 'synthall.software-suite' })}
              <br />
              <br />
              {intl.formatMessage({ id: 'synthall.pain-1' })}
              {' '}
              <strong>
                {intl.formatMessage({ id: 'synthall.pain-2' })}
              </strong>
              {intl.formatMessage({ id: 'synthall.pain-3' })}
              {' '}
              <strong>
                {intl.formatMessage({ id: 'synthall.pain-4' })}
              </strong>
            </>
          )}
        />
      </Container>
    </div>

    <section styleName="why-synthall">
      <Container>
        <h3>
          {intl.formatMessage({ id: 'synthall.why' })}
        </h3>

        <div styleName="columns">
          {whySynthall.map(({ title, content }) => (
            <div styleName="column" key={title}>
              <header>
                <Checkmark />

                <h5>
                  {intl.formatMessage({ id: title })}
                </h5>
              </header>

              <p>
                {intl.formatMessage({ id: content })}
              </p>
            </div>
          ))}
        </div>
      </Container>
    </section>

    <Container>
      <Features
        title="synthall.features.features"
        features={features}
      />
    </Container>

    <CostVersus />

    <Diagrams />
  </div>
);

SynthallPage.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(SynthallPage);
