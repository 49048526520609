import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import './Features.module.css';

const Features = ({
  intl,
  title,
  subtitle,
  features,
}) => (
  <div styleName="features">
    <header>
      <h3>
        {intl.formatMessage({ id: title })}
      </h3>

      {subtitle && (
        <p>
          {intl.formatMessage({ id: subtitle })}
        </p>
      )}
    </header>

    <div styleName="features-list">
      {features.map(({ Icon, label }) => (
        <div styleName="feature" key={label}>
          <Icon />
          <h5>
            {intl.formatMessage({ id: label })}
          </h5>
        </div>
      ))}
    </div>
  </div>
);

Features.propTypes = {
  intl: intlShape.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      Icon: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

Features.defaultProps = {
  subtitle: undefined,
};

export default injectIntl(Features);
